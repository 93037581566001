.hover {
  box-sizing: border-box;
  min-width: 217px;
  width: 15rem;
  height: fit-content;
  left: 301px;
  top: 396px;
  opacity: 1 !important;
  padding: 1rem;

  /* White */
  background-color: #ffffff !important;

  /* Gray/Border */
  border: 1px solid #e2e3e8 !important;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  border-radius: 14px !important;
  color: black !important;
}
